import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { v4 } from 'uuid'
import ProductCard from "../../components/ProductCard"
import ArrowIcon from "../../components/Icons/ArrowIcon"
import BreakpointUp from "../../components/Media/BreakpointUp"
import BreakpointDown from "../../components/Media/BreakpointDown"

const SectionGrid = styled.div`
	position: relative;
    display: flex;	
    flex-wrap: wrap;
	margin-left:-15px;
	margin-right:-15px;
`
const LeftSidebar = styled.div`
    position: relative;
    padding:0 15px;
    width: 100%;
    .sticky-wrapper {
      position: fixed;
      top: 130px;
      width: 100%;
    }
    .no-sticky {
      position: relative;
      top: 0;
    }
    ${BreakpointUp.lg`		
        flex:0 0 16.666667%;
	`}
  @media(max-width: 1023px) {
    .sticky-wrapper {
      position: relative !important;
      top: 0;
    }
  }
`
const RightContent = styled.div`
    padding:0 15px;
    width: 100%;
    ${BreakpointUp.lg`		
        flex:0 0 83.333333%;
	`}
`

const TabNav = styled.div`
    width: 100%;
    margin-bottom:30px;
    ${BreakpointDown.lg`	
      display: block;
      overflow-x: auto;
      white-space: nowrap;
      margin:0 auto 30px;
      text-align:center;
    `}
`

const TabItem = styled.div`
    position: relative;
    display: block;
    padding: 15px 0;
    ${BreakpointDown.lg`
        display:inline-block;
        padding:0 15px;
        
    `}
    &.active{
        div{
            &:before{
                background-color:#020304;
            }
        }
    }
   
`
const TabLink = styled.div`
    color: #666;
    font-weight: 700;
    display: block;
    position: relative;
    line-height: 20px;
    padding: 10px 0;
    ${BreakpointUp.lg`
    padding: 0 0 0 20px;
    `}
    &:before{
        content: '';
        left: 0;
        background-color: transparent;
        display: inline-block;
        position: absolute;
        height: 4px;
        width: 100%;
        top:auto;
        bottom:0;
        ${BreakpointUp.lg`
            height: 100%;
            width: 4px;
            top: 0;
            bottom:auto;
		`}  
    }
    &:hover {
		  color: #020304;
      cursor: pointer;
    }	
`

const TabContent = styled.div`
    margin:0;
    ${BreakpointUp.sm`
		margin:0 0 0 -40px;
	`}
`
const TabPane = styled.div`
    ${BreakpointUp.sm`
        padding:0 40px;	
    `}
`
const ExploreMore = styled.div`
    display:block;
    text-align:center;
    margin:30px 0;
`
const LinkButton = styled(Link)`
    display:inline-block;
    color:#020304;
    font-weight:700;
    position:relative;
    line-height:30px;
    &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        height: 2px;
        transition: -webkit-transform .4s cubic-bezier(.77,0,.175,1);        
        transition: transform .4s cubic-bezier(.77,0,.175,1);
        transition: transform .4s cubic-bezier(.77,0,.175,1),-webkit-transform .4s cubic-bezier(.77,0,.175,1);        
        transform: translateZ(0) scaleX(1);
        background: #020304;
        transform-origin: right center;    
        transform: translateZ(0) scaleX(0);        
    }
    & svg {
        transition: transform 0.3s ease-out;
    }
    &:hover{
        &:after {
            transform-origin: left center;        
            transform: translateZ(0) scaleX(1);
        }    
        color:#020304;
        svg{
            fill:#020304;
            transform: translateX(4px);
        }  
    }
	span{
        display:inline-block;
        vertical-align: middle;
        line-height:20px;
        + svg{
            margin-left:10px;
        }
    }
	svg{
        display:inline-block;
        vertical-align: middle;
    }
`

const RoofProducts = styled.div`
  max-width: 1124px;
  width: 100%;
  margin: auto;
`

class ProductCardList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSticky: false,
      previousPos: 0,
      products: this.props.data,
      currentTab: "all",
      showNumber: 3,
    }
    this.showAllProducts = this.showAllProducts.bind(this)
    this.showRoof = this.showRoof.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
  }
  showAllProducts() {
    this.setState({
      products: this.props.data,
      currentTab: "all"
    })
  }
  showRoof(roof) {
    let currentProducts = []
    const { data } = this.props
    data.forEach(function (item) {
      if (item.node.productRoof.roofName === roof) {
        currentProducts.push(item)
      }
    })
    this.setState({
      products: currentProducts,
      currentTab: roof
    })
  }
  handleScroll(event) {
    if (this.myRef) {
      const startOffset = this.myRef.getBoundingClientRect().top
      const endOffset = this.endRef.getBoundingClientRect().top
      if (startOffset <= 100 && endOffset > 200) {
        this.setState({
          isSticky: true,
        })
      } else {
        this.setState({
          isSticky: false,
        })
      }
    }
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, { passive: true })
    const { limit } = this.props
    if (limit) {
      this.setState({
        showNumber: limit
      })
    }
  }

  renderProductList = () => {
    const { buttonTxt, buttonLink } = this.props
    const { products, currentTab, showNumber, isSticky } = this.state

    return (
      <TabContent>
        <TabPane>
          {
            products.map((item, i) => {
              if (showNumber === "all") {
                return (
                  <ProductCard key={v4()} data={item.node} showQuote={this.props.showQuote} />
                )
              } else {
                if (i < showNumber) {
                  return (
                    <ProductCard key={v4()} data={item.node} showQuote={this.props.showQuote} />
                  )
                }
              }
            })
          }
        </TabPane>
        <ExploreMore>
          {showNumber === "all" ? (<></>) : (<LinkButton to={buttonLink}><span>{buttonTxt}</span><ArrowIcon /></LinkButton>)}
        </ExploreMore>
      </TabContent>
    )
  }

  render() {
    const { buttonTxt, buttonLink } = this.props
    const { products, currentTab, showNumber, isSticky } = this.state
    return (
      <div>
        {this.props.roof ? (
          <RoofProducts>
            {this.renderProductList()}
          </RoofProducts>
        ) : (
          <SectionGrid>
            <LeftSidebar>
              <TabNav className="isSticky" >
                <TabItem className={currentTab === "all" ? "active" : ""}>
                  <TabLink onClick={this.showAllProducts} >All Products</TabLink>
                </TabItem>
                <TabItem className={currentTab === "Vertical Roof" ? "active" : ""}>
                  <TabLink onClick={() => this.showRoof("Vertical Roof")}>Vertical Roof</TabLink>
                </TabItem>
                <TabItem className={currentTab === "A-Frame Roof" ? "active" : ""}>
                  <TabLink onClick={() => this.showRoof("A-Frame Roof")}>A-Frame Roof</TabLink>
                </TabItem>
                <TabItem className={currentTab === "Regular Roof" ? "active" : ""}>
                  <TabLink onClick={() => this.showRoof("Regular Roof")}>Regular Roof</TabLink>
                </TabItem>
              </TabNav>
            </LeftSidebar>
            <RightContent>
              {this.renderProductList()}
            </RightContent>

          </SectionGrid>
        )}
        <div ref={c => (this.endRef = c)} ></div>
      </div>

    )
  }
}


export default ProductCardList